import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import {
    isoWebPageURL,
    WagtailPageID,
} from "tsi-common-react/src/models/nominals";
import { check } from "tsi-common-react/src/models/utils";
import {
    dynamicPlaceComponent,
    placeComponent,
} from "tsi-common-react/src/utils/react";
import { readyStateComplete } from "tsi-common-react/src/utils/events";
import { urls } from "tsi-common-react/src/utils/urls";
import { Dispatchers } from "tsi-common-react/src/apps/common/dispatchers";
import { Loaders } from "tsi-common-react/src/apps/common/loaders";
import { registerCascades } from "tsi-common-react/src/apps/common/cascades";
import { snapUserDataLayerState } from "tsi-common-react/src/api/datalayer";
import { rehydratingStore, store } from "../store";
import { getMenuConfiguration } from "./menu";
import { NearestStoreInfo } from "./components/NearestStoreInfo";

// These components aren't lazy loaded because they appear on every page
import { MainMenu } from "./components/MainMenu";
import { AccountMenu } from "tsi-common-react/src/apps/authentication/components/AccountMenu";
import { SessionKeepAlive } from "tsi-common-react/src/apps/authentication/components/SessionKeepAlive";
import { StickyFooter } from "./components/StickyFooter";
import { TimedEmail } from "tsi-common-react/src/models/timed-email";
import { getPageSetting } from "tsi-common-react/src/utils/settings";

export const dispatchers = new Dispatchers(store.dispatch);
export const loaders = new Loaders(dispatchers);

const appSlugName = getPageSetting("app-slug");
const isOutletSite = appSlugName === "sleepoutfittersoutlet";

// Register store cascades
registerCascades(store);

// Load data for main menu from Wagtail JSON
const menuItems = getMenuConfiguration();
if (menuItems) {
    placeComponent(
        '[data-place-react="main-menu-dropdown"]',
        <MainMenu menuItems={menuItems} />,
    );
}

// Render account menu
dynamicPlaceComponent('[data-place-react="auth-login-menu-link"]', async () => {
    if (isOutletSite) {
        return null;
    }
    return (
        <Provider store={store}>
            <AccountMenu />
        </Provider>
    );
});

// Render Sticky Footer
dynamicPlaceComponent('[data-place-react="sticky-footer"]', async () => {
    return (
        <Provider store={store}>
            <StickyFooter />
        </Provider>
    );
});

// Render standalone login forms
dynamicPlaceComponent(
    '[data-place-react="auth-login-standalone"]',
    async (elem) => {
        const { LoginForm } = await import(
            "tsi-common-react/src/apps/authentication/components/LoginForm"
        );
        const nextURL = elem.dataset.continueUrl
            ? isoWebPageURL.wrap(elem.dataset.continueUrl)
            : undefined;
        return <LoginForm nextURL={nextURL} />;
    },
);

// Render Session Keep-Alive Modal
placeComponent(
    '[data-place-react="session-keep-alive-modal"]',
    <SessionKeepAlive />,
);

placeComponent(
    '[data-place-react="nearest-store-info"]',
    <Provider store={store}>
        <NearestStoreInfo />
    </Provider>,
);

if (!isOutletSite) {
    placeComponent(
        '[data-place-react="nearest-store-info-minimal"]',
        <Provider store={store}>
            <NearestStoreInfo minimal={true} />
        </Provider>,
    );
}

// Render Individual Store Info
dynamicPlaceComponent(
    '[data-place-react="individual-store-info"]',
    async (elem) => {
        if (!elem.dataset.pageId) {
            return null;
        }
        const { IndividualStoreInfo } = await import(
            "./components/IndividualStoreInfo"
        );
        const pageID = check(
            WagtailPageID.decode(parseInt(elem.dataset.pageId, 10)),
        );
        return (
            <Provider store={store}>
                <IndividualStoreInfo pageID={pageID} />
            </Provider>
        );
    },
);

// Render Value Props bar (generally on the home page)
dynamicPlaceComponent('[data-place-react="value-props-bar"]', async (elem) => {
    const { ValuePropsBar } = await import(
        "tsi-common-react/src/common/ValuePropsBar"
    );
    const parsedData = JSON.parse(elem.dataset.cms || "");
    return <ValuePropsBar valueProps={parsedData.value_props} />;
});

// Render countdown timer
dynamicPlaceComponent('[data-place-react="countdown-timer"]', async (elem) => {
    const { CountdownTimer } = await import(
        "tsi-common-react/src/apps/countdown-timer/container/CountdownTimer"
    );
    const timerData = JSON.parse(elem.dataset.timers || "");
    const timersExpiredPromo = elem.dataset.promo || "";
    return (
        <CountdownTimer
            timerData={timerData}
            timersExpiredPromo={timersExpiredPromo}
        />
    );
});

// Render user data layer element
dynamicPlaceComponent('[data-place-react="user-data-layer"]', async (elem) => {
    await readyStateComplete;
    // Pause for 1 second to allow the data layer push scripts to run after readyState is complete
    await (async () => {
        return new Promise((res) => {
            setTimeout(res, 1000);
        });
    })();
    const orderID = elem.dataset.order || "";
    await snapUserDataLayerState(orderID);
    return null;
});

// Place Live Chat Links
dynamicPlaceComponent('[data-place-react="chat-link"]', async (elem) => {
    const { ChatLink } = await import(
        "tsi-common-react/src/apps/chat/ChatLink"
    );
    return (
        <ChatLink
            className={elem.dataset.chatLinkClass}
            chatOnlineText={elem.dataset.chatOnlineText}
            chatOfflineText={elem.dataset.chatOfflineText}
            chatOfflineLink={
                elem.dataset.chatOfflineLink
                    ? isoWebPageURL.wrap(elem.dataset.chatOfflineLink)
                    : urls.pageURL("customer-service")
            }
        />
    );
});

/**
 * Render Email Capture in The Site footer
 */
dynamicPlaceComponent(
    '[data-place-react="email-capture-site-footer"]',
    async () => {
        const { EmailCapture } = await import("./components/EmailCapture");

        return <EmailCapture />;
    },
);

/**
 * Render TimedEmailCaptureBlock
 */
dynamicPlaceComponent(
    '[data-place-react="timed-email-capture-block"]',
    async (elem) => {
        const { TimedEmailModal } = await import(
            "tsi-common-react/src/apps/common/containers/TimedEmailModal"
        );
        const data = check(
            TimedEmail.decode(JSON.parse(elem.dataset.cms || "")),
        );
        const email_content = data.email_content[0];
        return (
            <TimedEmailModal
                title={data.title}
                emailContent={email_content}
                timing={data.timing}
            />
        );
    },
);

// Render the CSR toolbar
(async () => {
    // Wait for Redux store to finish loading
    await rehydratingStore;

    const user = await loaders.loadCurrentUser();
    if (!user || !user.is_csr) {
        return;
    }
    // Load the toolbar component
    const { Toolbar } = await import(
        "tsi-common-react/src/apps/csr/components/Toolbar"
    );

    // Create container elements
    const csrToolbarContainer = document.createElement("div");
    csrToolbarContainer.id = "oscarcsr-toolbar-reset";
    document.body.prepend(csrToolbarContainer);

    const csrToolbar = document.createElement("div");
    csrToolbar.id = "oscarcsr-toolbar";
    csrToolbarContainer.appendChild(csrToolbar);

    // Render the toolbar
    createRoot(csrToolbar).render(
        <Provider store={store}>
            <Toolbar
                dashboard={isoWebPageURL.wrap("/store/dashboard/")}
                basket={isoWebPageURL.wrap("/store/basket/")}
            />
        </Provider>,
    );
})();
