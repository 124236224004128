import React from "react";
import { t } from "ttag";
import { UnifyMerchantLibrary } from "tsi-common-react/src/apps/synchrony/UnifyMerchantLibrary";
import { UnifiModalTrigger } from "./UnifiModalTrigger";

interface IProps {
    termThreshold: string;
}

export const UnifiPreQualAdButtons = (props: IProps) => (
    <div className="pre-qual-block__buttons">
        <UnifyMerchantLibrary />
        <UnifiModalTrigger
            className="button al-HP-pre-qual-block__button--pre-qualify"
            termThreshold={props.termThreshold}
        >
            {t`See if You Pre-qualify`}
        </UnifiModalTrigger>
        <p>
            {t`Get a decision in seconds with no impact to your credit bureau score.`}
        </p>
    </div>
);
